import { AssistantAskMode, DESIA_EVENT, Dossier, ResponseChatList } from "../../types/types";
import { TypographyBody, TypographyH2 } from "../ui/Typography";
import { ChatBox } from "./ChatBox";
import { SuggestionCard } from "./SuggestionCard";
import { useContext, useEffect, useRef } from "react";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "@/contexts/AssistantContext";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { getTimestamp } from "@/utils/utils";
import { ChatList } from "../ChatList";
import { DossierBreadcrumb } from "../Dossier/DossierBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { fetchDossierDetail } from "../Dossier/dossierSlice";

interface Props {
    dossier?: Dossier,
    expertModeAvailable: boolean;
    hasSourceSelector: boolean;
    handleSubmit: ({ message, }: { message: string, mode: AssistantAskMode }) => void;
}

export function NewAsk({ dossier, expertModeAvailable, handleSubmit }: Props) {
    const dossierDetails = useSelector((state: RootState) => state.dossier.dossierDetails);

    const { store, dispatch } = useContext(AssistantStoreContext);
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    const containerRef = useRef<HTMLDivElement>(null)
    const appDispatch = useDispatch<AppDispatch>()

    const dossierDetail = dossierDetails[dossier?.id || '']?.data || undefined

    useEffect(() => {
        // prevent scroll down after loading lists since it saves conversation scroll position when you navigate back
        if (!loading) {
            containerRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' })
        }
    }, [loading])

    useEffect(() => {
        if (!dossier) return
        appDispatch(fetchDossierDetail(dossier.id))
    }, [dossier])

    return (
        <>
            <div className="flex flex-col justify-center align-center h-full -mt-20">
                <div className="h-[1px] mb-10" ref={containerRef}>

                </div>
                {dossier && (
                    <DossierBreadcrumb
                        dossier={dossier}
                        component={
                            <TypographyBody isStrong={true} className="whitespace-nowrap">
                                New chat
                            </TypographyBody>
                        }
                    />
                )}
                <div className="flex flex-col gap-16 justify-center align-center w-full max-w-[710px] mt-20 mx-auto">
                    <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-center mx-auto">
                        <TypographyH2>Ask Desia</TypographyH2>
                        <div className="h-8 w-[1px] bg-system-body hidden sm:!flex"></div>
                        <TypographyBody className="text-system-body text-center sm:text-left">
                            Level up your investment research.
                            <br />
                            Minutes, not hours.
                        </TypographyBody>
                    </div>

                    <div className="flex flex-col gap-8 justify-center">
                        <ChatBox
                            expertModeAvailable={expertModeAvailable}
                            handleSubmit={handleSubmit}
                            additionalControls={true}
                            status="Ready"
                            isConversation={false}
                            showBorder={false}
                            dossierDetail={dossierDetail}
                        />

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
                            <SuggestionCard
                                text="What recent mergers or acquisitions have occurred in the telecom sector?"
                                highlights={['recent mergers', 'acquisitions']}
                                bgColor="#FFE3E3"
                                textColor='#7633AA'
                                handleSubmit={handleSubmit}
                            />
                            <SuggestionCard
                                text="What are the key factors driving NVIDIA's revenue growth?"
                                highlights={["NVIDIA's revenue growth"]}
                                bgColor="#DAFFE0"
                                textColor='#025477'
                                handleSubmit={handleSubmit}
                            />
                            <SuggestionCard
                                text="Determine the staffing and organisational changes needed post-acquisition."
                                highlights={['staffing and organisational changes']}
                                bgColor="#CEF4FD"
                                textColor='#2D4974'
                                handleSubmit={handleSubmit}
                            />
                        </div>
                    </div>

                    <div className="mt-[0px] sm:mt-[56px]">
                        <ChatList
                            threads={store.list}
                            loading={loading}
                            error={error}
                            type='chat'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
