import { useContext, useEffect } from "react";
import { ASSISTANT_ACTIONS, AssistantStoreContext, SourceDocumentId, getLiveConversationById } from "../../contexts/AssistantContext"
import { getTimestamp } from "../../utils/utils";
import { useSocketQuery } from "../../hooks/useSocketQuery";
import { AssistantAskMode, DESIA_EVENT, RequestAssistantAsk, ResponseChatDetails, ResponseChatStream, WebSocketRequestWrapper } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import { Conversation } from "./Conversation";
import { UserContext } from "../../contexts/UserContext";
import { ErrorMessage } from "../ErrorMessage";
import { getAskTools } from "@/utils/ask";
import { Skeleton } from "../ui/skeleton";
import { ResponsiveContainer } from "./ResponsiveContainer";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

function ConversationNotFound() {
    const navigate = useNavigate();
    return (
        <div>
            Conversation not found...
            <button onClick={() => {
                navigate("/assistant/ask");
            }}>
                Ask new question
            </button>
        </div>
    )
}

function ConversationLoadFailed({ message }: { message: string }) {
    return (
        <div className="w-fit mx-auto">
            <ErrorMessage message={`We failed to load that conversation (${message}). Our engineers have been informed. Try again later. `} />
        </div>
    )
}

export function ExistingConversation() {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
    const dossierDetail = useSelector((state: RootState) => state.dossier.dossierDetails)

    const { store, dispatch } = useContext(AssistantStoreContext);
    const { settings } = useContext(UserContext);
    const { conversation_id, dossier_id } = useParams();

    const requestId = conversation_id || "requestId"; // fixme
    const currentConversation = store.list.find((b) => b.conversationId === conversation_id)
    const dossierConversation = dossierDetail[dossier_id || '']?.data?.conversations.find((v) => v.id === conversation_id)
    const dossier = dossiers.data?.find((v) => v.id === dossier_id)

    function handleSelectCitation(messageId: string, documentId: SourceDocumentId[]) {
        dispatch({
            action: ASSISTANT_ACTIONS.SELECT_CITATION,
            data: { messageId, documentId }
        })
    }

    function handleShowAllCitations(messageId: string) {
        dispatch({
            action: ASSISTANT_ACTIONS.SHOW_ALL_CITATIONS,
            data: { messageId }
        })
    }

    const { executeQuery, state: queryState } = useSocketQuery({
        event: DESIA_EVENT.CHAT_ASK,
        request: {
            requestId: requestId,
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: true,
            callback: (response) => {
                const res = response.data as ResponseChatStream; // fixme
                dispatch({
                    action: ASSISTANT_ACTIONS.STREAMING_RESPONSE,
                    data: {
                        ...res,
                        requestId: response.requestId,
                        timestamp: response.timestamp,
                    }
                })
            }
        },
    })

    // todo: error handling
    const { executeQuery: fetchConversation, state: chatDetailsState } = useSocketQuery({
        event: DESIA_EVENT.CHAT_DETAILS,
        request: {
            requestId: requestId,
            timestamp: getTimestamp(),
            params: {},
            conversationId: conversation_id, // todo: type as WebSocketRequest and place params in obj
        },
        options: {
            manual: true,
            cacheTimeoutMs: 1_000 * 60,
            callback: (res) => {
                if (!res.error && res.data.messages) {
                    dispatch({
                        action: ASSISTANT_ACTIONS.FETCH_CONVERSATION,
                        data: res.data as ResponseChatDetails
                    })
                }
            },
        }
    });

    const conversation = getLiveConversationById({
        store,
        conversationId: conversation_id || "",
        requestId,
    })

    function handleAsk({ message, mode }: { message: string, mode: AssistantAskMode }) {
        const timestamp = getTimestamp();
        dispatch({
            action: ASSISTANT_ACTIONS.FOLLOW_UP_ASK,
            data: {
                conversationId: conversation_id!, // fixme
                requestId: `${requestId}_followup_${conversation.length}`,
                question: message,
                timestamp,
                mode,
            }
        })
        const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
            requestId: `${requestId}_followup_${conversation.length}`,
            timestamp,
            params: {
                message: message,
                conversationId: conversation_id,
                connectorsV2: settings.assistant.sources,
                model: settings.assistant.parameters.model,
                seed: settings.assistant.parameters.seed,
                temperature: settings.assistant.parameters.temperature,
                systemPrompt: settings.assistant.parameters.systemPrompt,
                mode: mode,
                ...getAskTools(settings),
            }
        }
        executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request,
        });
    }

    useEffect(() => {
        if (conversation_id) {
            fetchConversation({
                event: DESIA_EVENT.CHAT_DETAILS,
                request: {
                    requestId: conversation_id,
                    timestamp: (new Date()).getTime(),
                    conversationId: conversation_id,
                    params: {},
                },
            })
        }
    }, [conversation_id])

    if (!conversation_id) {
        return <ConversationNotFound />
    }

    if (chatDetailsState.error) {
        return <ConversationLoadFailed message={chatDetailsState.error} />
    }

    if (chatDetailsState.loading) {
        return <ResponsiveContainer>
            <div className="max-w-[710px] mx-auto">
                <Skeleton className="w-full h-[30px] my-2" />

                <Skeleton className="w-4/6 h-[20px] my-2" />
                <Skeleton className="w-full h-[20px] my-2" />
                <Skeleton className="w-full h-[20px] my-2" />
                <Skeleton className="w-5/6 h-[20px] my-2" />
            </div>
        </ResponsiveContainer>
    }

    return <Conversation
        handleAsk={handleAsk}
        selectCitation={handleSelectCitation}
        showAllCitations={handleShowAllCitations}
        selectedCitations={store.state.selectedCitations}
        conversation={conversation}
        queryState={queryState}
        sources={settings.assistant.sources}
        dossier={dossier}
        title={currentConversation?.query || dossierConversation?.title}
    />
}
