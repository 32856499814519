import { MessageCircleMore } from "lucide-react"
import { TypographyBody, TypographyH4 } from "./ui/Typography"
import Divider from "./ui/divider"
import { CustomAlert } from "./CustomAlert"
import { ChatSummary, Dossier } from "@/types/types"
import { formatDate, isToday, isYesterday } from "date-fns"
import { Thread } from "./Threads/Thread"
import { Skeleton } from "./ui/skeleton"
import { Button } from "./ui/button"
import MessageCircleQuestion from "@/assets/MessageCircleQuestion"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import ChevronDown from "@/assets/ChevronDown"

export interface ChatListProps {
    threads: ChatSummary[]
    loading: boolean
    error: string | null
    type: 'chat' | 'dossier',
    dossier?: Dossier
}

export const ChatList = ({ threads, loading, error, type, dossier }: ChatListProps) => {
    const [shownThreadsCount, setShownThreadsCount] = useState(5)

    const navigate = useNavigate()

    const shownThreads = type === 'dossier' ? threads.slice(0, shownThreadsCount) : threads

    const groupThreadsByDate = (threads: ChatSummary[]): { [date: string]: ChatSummary[] } => {
        return threads.reduce((acc: { [date: string]: ChatSummary[] }, item) => {
            const date = new Date(item.updated_at)

            const dateString = isToday(date) ? 'Today' : isYesterday(date) ? 'Yesterday' : formatDate(date, 'd MMMM' + (date.getFullYear() == new Date().getFullYear() ? '' : ' yyyy'))
            if (!acc[dateString]) {
                acc[dateString] = []
            }
            acc[dateString].push(item)
            acc[dateString] = acc[dateString].sort((a, b) => b.updated_at - a.updated_at)
            return acc
        }, {})
    }

    const groupedThreads = groupThreadsByDate(shownThreads)

    useEffect(() => {
        setShownThreadsCount(window.innerWidth > 1024 ? threads.length : 5)
    }, [threads])

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-2 px-3 items-center">
                <MessageCircleMore className="h-6 w-6 shrink-0 stroke-[1.5px]" />

                <TypographyH4>
                    {type === 'dossier' ? 'Chats' : 'Past chats'}
                </TypographyH4>
            </div>

            <Divider />

            <div className={`flex flex-col ${type === 'dossier' ? 'gap-4 tablet:gap-8' : 'gap-8'}`}>
                {error && (
                    <CustomAlert
                        variant='error'
                        description="We could not load chats at this time. Please try again later."
                    />
                )}

                {!loading && Object.keys(groupedThreads).map((dateString) => {
                    const threads = groupedThreads[dateString] || []
                    return (
                        <div className="flex flex-col gap-4 pb-2">
                            <TypographyBody isStrong={true} className="px-3 pt-3">
                                {dateString}
                            </TypographyBody>

                            <div className={`grid grid-cols-1 ${threads.length === 1 ? '' : `${type === 'dossier' ? 'mobile:!grid-cols-2 tablet:!grid-cols-1' : 'sm:!grid-cols-2'}`}  gap-4`}>
                                {threads.map((thread) => {
                                    return (
                                        <Thread thread={thread} type={type} />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}

                {loading && (
                    <div className="flex flex-col gap-4 pb-2">
                        <div className={`grid grid-cols-1 ${type === 'chat' ? 'sm:grid-cols-2' : ''}gap-4`}>
                            <Skeleton className="w-full h-10" />
                            <Skeleton className="w-full h-10" />
                            <Skeleton className="w-full h-10" />
                            <Skeleton className="w-full h-10" />
                            <Skeleton className="w-full h-10" />
                            <Skeleton className="w-full h-10" />
                        </div>
                    </div>
                )}

                {Object.keys(groupedThreads).length === 0 && type === 'dossier' && dossier && !error && (
                    <div className="flex flex-col gap-4 mx-auto mt-2">
                        <TypographyBody className="text-system-body">
                            There are no threads yet.
                        </TypographyBody>

                        <Button variant='secondary' className="w-fit mx-auto" onClick={() => navigate(`/dossier/${dossier.id}/assistant/ask`)}>
                            <div className="flex gap-2">
                                <MessageCircleQuestion className="size-6 !stroke-link" />

                                Ask Desia
                            </div>
                        </Button>
                    </div>
                )}

                {threads.length > 5 && type === 'dossier' && shownThreadsCount < threads.length && (
                    <div className="mx-auto">
                        <Button variant='tertiary' onClick={() => setShownThreadsCount(shownThreadsCount + 5)}>
                            <div className="flex gap-2">
                                Show more chats

                                <ChevronDown />
                            </div>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}