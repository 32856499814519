import { ReactNode } from "react";
import texture from '../../assets/bg-texture.png'

type MainProps = {
    component: ReactNode;
    tall?: boolean;
    scrollable?: boolean;
}


export function Main({ component, tall, scrollable }: MainProps) {
    return (
        <main
            style={{ backgroundImage: `url(${texture})` }}
            className={`px-8 w-full mx-auto sm:pt-10 pb-10 md:pb-20 ${tall ? "" : "lg:pb-30"} bg-repeat bg-top ${scrollable === false ? 'overflow-hidden' : 'overflow-auto'}`}
        >
            <div
                // tailwind max width 768px, center aligned
                className={`${tall ? "w-full" : "max-w-[956px]"}  mx-auto`}
            >
                {component}
            </div>
        </main>
    )
}
