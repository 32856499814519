import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { DossierDetail, QueryStatus, SourceConnector } from "../../types/types";
import { Checkbox } from "../ui/checkbox";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import DocumentSelectorDialog from "./DocumentSelectorDialog";
import { plural } from "@/utils/utils";
import Stack from "@/assets/Stack";
import { CustomTooltip } from "../CustomTooltip";
import { Button } from "../ui/button";
import { DocumentStoreContext } from "@/contexts/DocumentContext";

export function SourceSelector({ compact, dossierDetail }: { compact?: boolean, dossierDetail?: DossierDetail }) {
    const { settings, updateSettings } = useContext(UserContext);
    const documentContext = useContext(DocumentStoreContext);

    const sources = settings.assistant.sources;
    // @ts-expect-error
    const selectedFiles = sources.find((v) => v.id === 'internal-search')?.include || []

    const dossierFileResources = (dossierDetail?.sources.map((v) => {
        return documentContext.store.data?.find((f) => f.document_id === v.resource_id)
    }) || [])
    const dossierFileNames = dossierFileResources.map((v) => v?.document_name).filter((v): v is string => Boolean(v))
    const truncatedDossierName = dossierDetail && (dossierDetail.subject.length > 30 ? dossierDetail.subject.slice(0, 30) + '...' : dossierDetail.subject)

    function toggleSource(source: SourceConnector) {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: settings.assistant.sources.find((v) => v.id === source.id) ? settings.assistant.sources.filter(s => s.id !== source.id) : [...settings.assistant.sources, source]
                }
            }
        })
    }

    useEffect(() => {
        // @ts-expect-error
        const previousIncludedDocuments = settings.assistant.sources.find((v) => v.id === 'internal-search')?.include || []

        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: [...settings.assistant.sources.filter((v) => v.id !== 'internal-search'), { id: 'internal-search', include: dossierFileNames }]
                }
            }
        })

        return () => {
            updateSettings({
                settings: {
                    ...settings,
                    assistant: {
                        ...settings.assistant,
                        sources: [...settings.assistant.sources.filter((v) => v.id !== 'internal-search'), { id: 'internal-search', include: previousIncludedDocuments }]
                    }
                }
            })
        }
    }, [dossierDetail])

    useEffect(() => {
        if (documentContext.store.status === QueryStatus.IDLE) {
            documentContext.handlers.listDocuments()
        }
    }, [])

    return (
        <>
            <div className={`${compact ? "" : "mt-4"} px-2`}>
                <div className="flex gap-6 items-center">
                    <TypographyBody>Sources:</TypographyBody>
                    <div className="flex items-center gap-2">
                        <Checkbox id="internal-search-checkbox"
                            checked={!!sources.find((v) => v.id === 'internal-search')}
                            onCheckedChange={() => {
                                toggleSource({ id: 'internal-search' });
                            }}
                            label={dossierDetail && selectedFiles.every((v: string) => dossierFileNames.includes(v)) ? (truncatedDossierName || '') : selectedFiles.length ? `${selectedFiles.length} ${plural('file', selectedFiles.length)}` : "Library"}
                        />

                        <DocumentSelectorDialog
                            type='ask'
                            trigger={
                                <Button className="mt-[2px]" variant={'icon'} size='icon' data-tooltip-id="select-files">
                                    <Stack />
                                </Button>
                            }
                        />
                    </div>

                    <div className="flex items-center gap-2">
                        <Checkbox id="web-search-checkbox"
                            checked={!!sources.find((v) => v.id === 'web-search')}
                            onCheckedChange={() => {
                                toggleSource({ id: 'web-search' });
                            }}
                            label="Web"
                        />
                    </div>
                </div>
            </div>
            <CustomTooltip
                id="select-files"
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Select files
                </TypographyLabel>
            </CustomTooltip>
        </>
    )
}
