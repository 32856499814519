import { ResponseChatStream, Citation, SourceDocument, SystemMessage } from "@/types/types";
import { checkWebLink, getFileId } from "./utils";
import FileType from "@/assets/FileType";
import FileSpreadsheet from "@/assets/FileSpreadsheet";
import FileBarChart from "@/assets/FileBarChart";
import { ReactNode } from "react";
import FileText from "@/assets/FileText";
import { FolderOpen, HardDrive, PanelTop, User } from "lucide-react";
import OneDriveIcon from "@/assets/OneDriveIcon";
import SharepointIcon from "@/assets/SharepointIcon";
import OneDriveIconCompact from "@/assets/OneDriveIconCompact";
import SharepointCompact from '../assets/SharepointCompact.png'
import IconDossier1 from '@/assets/icon-dossier-1.png'
import IconDossier2 from '@/assets/icon-dossier-2.png'
import IconDossier3 from '@/assets/icon-dossier-3.png'
import IconDossier4 from '@/assets/icon-dossier-4.png'
import IconDossier5 from '@/assets/icon-dossier-5.png'
import IconDossier6 from '@/assets/icon-dossier-6.png'
import IconDossier7 from '@/assets/icon-dossier-7.png'
import IconDossier8 from '@/assets/icon-dossier-8.png'
import IconDossier9 from '@/assets/icon-dossier-9.png'
import IconDossier10 from '@/assets/icon-dossier-10.png'

// web sources could have the same document_id
// so instead use the url as the identifier
// edit: use ids + urls instead of urls only, there are cases where 2 sources link to the same url
function getGlobalDedupedId(d: SourceDocument, useWebIds: boolean = true) {
    const isWebLink = checkWebLink(d.url);
    if (isWebLink) {
        if (useWebIds) {
            return `${d.document_id}-${d.url}`;
        } else {
            return d.url
        }
    }
    // file
    const fileId = getFileId(d.document_id);
    return fileId;
}

export function getGlobalUniqueDocuments(documents: SourceDocument[], useWebIds?: boolean) {
    let deduped: SourceDocument[] = [];
    let dedupedIds = new Map();
    documents.forEach(d => {
        const dId = getGlobalDedupedId(d, useWebIds);
        if (!dedupedIds.has(dId)) {
            deduped.push(d);
            dedupedIds.set(dId, true);
        }
    });
    return deduped;
}

export function mergeIncomingStreamWithSystemMessage(prev: SystemMessage, incoming: ResponseChatStream) {
    let merged = { ...prev }
    switch (incoming.event_type) {
        case 'stream-start': {
            break;
        }
        case 'search-results': {
            merged.data.documents = incoming.body.documents;
            break;
        }
        case 'text-generation': {
            merged.data.text = incoming.body.text;
            break;
        }
        case 'citation-generation': {
            merged.data.citations = [
                ...(merged.data.citations || []),
                ...(incoming.body.citations || [])
            ]
            break;
        }
        case 'stream-end': {
            // todo: consider only setting finished flag
            merged.data.text = incoming.body.text;
            merged.data.documents = incoming.body.documents;
            merged.data.citations = incoming.body.citations;
            merged.data.isFinished = true;
            break;
        }
        case 'followup-question': {
            merged.data.followUpQuestions = incoming.body.questions
            break
        }

        default:
            break;
    }
    return merged;
}

export function filterDocumentsByCited(documents: SourceDocument[], citations: Citation[], skipPendingCitations = false) {
    if (skipPendingCitations && citations.length === 0) {
        return documents;
    }
    const citedDocumentIds = [...new Set(citations.map(c => c.document_ids).flat())];
    const filtered = documents.filter(d => {
        const shouldInclude = citedDocumentIds.includes(d.document_id);
        return shouldInclude;
    })
    return filtered;
}

export const getFileIcon = (type: string, className?: string): ReactNode => {
    const style = `w-4 h-4 shrink-0 ${className}`
    switch (type.toLowerCase()) {
        case 'drive': return <HardDrive className={style} />
        case 'site': return <PanelTop className={style} />
        case 'user': return <User className={style} />
        case 'folder': return <FolderOpen className={style} />
        case "pdf":
        case "application/pdf":
            return <FileType className={style} />
        case "text":
        case "text/plain":
        case "text/html":
        case "text/htm":
        case "text/markdown":
        case "text/csv":
        case "csv":
        case "markdown":
        case "word":
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return <FileText className={style} />
        case "spreadsheet":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return <FileSpreadsheet className={style} />
        case "presentation":
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return <FileBarChart className={style} />
        default:
            return <FileType className={style} />
    }
}

export const getIntegrationIcon = (integration_code_name: string, compact?: boolean, className?: string) => {
    const style = `${className} shrink-0`
    switch (integration_code_name) {
        case 'microsoft-onedrive': return compact ? <OneDriveIconCompact className={style} /> : <OneDriveIcon />
        case 'microsoft-sharepoint': return compact ? <img src={SharepointCompact} className={style} /> : <SharepointIcon />
        default: return null
    }
}

export const getDossierIcon = (icon: string, className?: string) => {
    const style = `${className} size-6 shrink-0`
    switch (icon) {
        case '1': return <img className={style} src={IconDossier1} />
        case '2': return <img className={style} src={IconDossier2} />
        case '3': return <img className={style} src={IconDossier3} />
        case '4': return <img className={style} src={IconDossier4} />
        case '5': return <img className={style} src={IconDossier5} />
        case '6': return <img className={style} src={IconDossier6} />
        case '7': return <img className={style} src={IconDossier7} />
        case '8': return <img className={style} src={IconDossier8} />
        case '9': return <img className={style} src={IconDossier9} />
        case '10': return <img className={style} src={IconDossier10} />
        default: return <img className={style} src={IconDossier1} />
    }

}