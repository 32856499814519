import ArrowRight from "@/assets/ArrowRight"
import { Button } from "../ui/button"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { useNavigate } from "react-router-dom"
import AskDesiaHomeImage from '../../assets/img-home-ask-desia.png'
import DocGenHomeImage from '../../assets/img-home-doc-gen.svg'
import { useState } from "react"
import { NotAvailableDialog } from "../Resources/NotAvailableDialog"

export const HomeActivityCard = ({ type }: { type: 'ask' | 'docgen' }) => {
    const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false)

    const navigate = useNavigate()

    return (
        <>
            <div className="group h-[320px] w-full">
                <Button
                    variant='secondary'
                    size='fit'
                    className="p-0 flex flex-col h-full w-full items-start relative overflow-hidden !rounded-lg"
                    onClick={() => {
                        if (type === 'ask') {
                            navigate('/assistant/ask')
                        } else {
                            setShowNotAvailableDialog(true)
                        }
                    }}
                >
                    <TypographyH3 className="p-6 z-[2]">{type === 'ask' ? 'Ask Desia' : 'Generate document'}</TypographyH3>

                    <img className="absolute w-[443px] z-1 left-0 right-0 mx-auto px-4" src={type === 'ask' ? AskDesiaHomeImage : DocGenHomeImage} />
                    <div className="p-5 bg-system-secondary mt-auto w-[calc(100%-8px)] z-[2] m-1 rounded-md group-hover:bg-system-hover transition-colors">
                        <Button variant='tertiary' className="mt-auto w-full" onClick={() => navigate('/assistant/ask')}>
                            <div className="flex gap-2 w-full">
                                <TypographyBody className="w-full text-left">
                                    {type === 'ask' ? 'Browse or start a new conversation' : 'Browse or generate a new document'}
                                </TypographyBody>

                                <ArrowRight />
                            </div>
                        </Button>
                    </div>
                </Button>
            </div>
            <NotAvailableDialog open={showNotAvailableDialog} setOpen={(v) => setShowNotAvailableDialog(v)} variant='docgen' />
        </>
    )
}